import React, { useCallback, useEffect, useState } from 'react';
import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import CustomForm from '@shared/components/CustomForm';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import Divider from '@shared/components/Divider';
import { Input, H6, Spinner, Select, Checkbox, InputDatePicker, Textarea } from 'react-alicerce-components';
import { useNavigate } from 'react-router-dom';
import UserCard from '@modules/users/components/UserCard';
import TagsRender from '@modules/tags/components/TagsRender';
import { errorMessageBuilder, hasError, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { removeItemFromArrayByIndex } from '@shared/utils/removeIndexFromArray';
import ButtonAddCard from '@shared/components/ButtonAddCard';
import { useClass } from '@modules/classes/context/ClassContext';
import DateTimeRender from '@shared/components/DateTimeRender';
import { defaultType } from '@modules/classes/utils/validData/validClassType';
import { defaultClassStatus } from '@modules/classes/utils/validData/validClassStatus';
import { IUser } from '@modules/users/interfaces/IUser';
import { AgeGroupClass, ClassFormContainer } from './styles';
import GroupCard from '@modules/groups/components/GroupCard';
import PlaceCard from '@modules/places/components/PlaceCard';
import { FiCheck } from 'react-icons/fi';
import { AGE_GROUP_CONSTRAINTS } from '@shared/utils/validData/validAgeGroup';
import ParentRender from '@shared/components/ParentRender';
import IClass from '@modules/classes/interfaces/IClass';
import { translateDays } from '@modules/classes/utils/translateDays';
import StudentCard from '../../../students/components/StudentCard';
import { datePickerFormat } from '@shared/utils/datePickerFormat';
import InputErrorMessage from '@shared/components/ErrorMessages/InputErrorMessage';
import { DeleteCustomForm } from '@modules/classes/forms/DeleteCustomForm';
import AlertMessage from './partials/AlertMessage';
import { RedirectSuggestionAlert } from '../../../../shared/components/RedirectSuggestionAlert';
import Icon from 'react-eva-icons';
import { defaultModality } from '@modules/classes/utils/validData/validClassModality';

interface IClassFormProps {
  pathEnvolvedStudents?: string;
  pathEnvolvedGroups: string;
  pathEnvolvedPlaces: string;
  pathEnvolvedUsers: string;
  pathToInsertTime: string;
  errors: IInputErrorsFormat[];
  formAction: 'editing' | 'creating';
}

const ClassForm: React.FC<IClassFormProps> = ({
  pathEnvolvedStudents,
  pathEnvolvedGroups,
  pathEnvolvedPlaces,
  pathEnvolvedUsers,
  pathToInsertTime,
  formAction,
  errors,
}) => {
  const { classCurrent, setClassCurrent } = useClass();
  const { isLoading } = useGlobal();
  const navigate = useNavigate();
  const [customizationClass, setCustomizationClass] = useState<boolean>(false);

  const removeItemFromArray = useCallback(
    (arrayIndex, objKey) => {
      const newArray = removeItemFromArrayByIndex(arrayIndex, classCurrent[objKey] || []);
      setClassCurrent((oldState: IClass) => {
        return { ...oldState, [objKey]: newArray };
      });
    },
    [classCurrent, setClassCurrent]
  );

  const removeFromEnroll = useCallback(
    (arrayIndex, fieldName) => {
      const updateData = classCurrent[fieldName];
      const newEnrollArray = removeItemFromArrayByIndex(arrayIndex, updateData.enroll);
      setClassCurrent((oldState: IClass) => {
        return { ...oldState, [fieldName]: { ...updateData, enroll: newEnrollArray } };
      });
    },
    [classCurrent, setClassCurrent]
  );

  const setRemoveItem = useCallback(
    (data, fieldName, fieldKey) => {
      const { userId, ...restData } = data;

      const updateData = classCurrent[fieldName];
      const unenroll = classCurrent[fieldName]?.unenroll || [];
      setClassCurrent((oldState) => {
        return { ...oldState, [fieldName]: { ...updateData, unenroll: [...unenroll, { ...restData, [fieldKey]: userId }] } };
      });
    },
    [classCurrent, setClassCurrent]
  );

  const handleSelectChange = useCallback(
    (option, selectName) => {
      setClassCurrent((oldState: any) => {
        return { ...oldState, [selectName]: option.value };
      });
    },
    [setClassCurrent]
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
      const inputValue = event.target.value;
      const inputName = event.target.name;
      const dataType = event.target.getAttribute('datatype');

      let finalValue: string | number = inputValue;
      if (dataType === 'number') finalValue = isNaN(Number(inputValue)) ? inputValue : Number(inputValue);
      setClassCurrent((oldState: any) => {
        return { ...oldState, [inputName]: finalValue };
      });
    },
    [setClassCurrent]
  );

  const handleChangeCheckbox = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const isChecked = event.target.checked;
      const value = event.target.id;
      if (isChecked) {
        setClassCurrent((oldState: any) => {
          return { ...oldState, age_groups: [...classCurrent.age_groups, value] };
        });
      } else {
        const newAgeGroups = classCurrent.age_groups.filter((ag) => ag !== value);
        setClassCurrent((oldState: any) => {
          return { ...oldState, age_groups: newAgeGroups };
        });
      }
    },
    [classCurrent.age_groups, setClassCurrent]
  );

  const handleChangeCheckboxCustomizationClass = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setCustomizationClass((oldState) => {
        if (oldState) {
          setClassCurrent((oldState: any) => {
            return { ...oldState, customization: null, isCustomClass: !customizationClass };
          });
        }
        if (!oldState) {
          setClassCurrent((oldState: any) => {
            return { ...oldState, customization: '', isCustomClass: !customizationClass };
          });
        }
        return !oldState;
      });
    },
    [customizationClass, setClassCurrent]
  );

  const clearParent = useCallback(
    (key: 'group' | 'place') => {
      setClassCurrent((oldState: any) => {
        return { ...oldState, [key]: undefined, [key + '_id']: undefined };
      });
    },
    [setClassCurrent]
  );

  useEffect(() => {
    if (!!classCurrent.customization) {
      setCustomizationClass(true);
      setClassCurrent((oldState: any) => {
        return { ...oldState, isCustomClass: true };
      });
    }
  }, [classCurrent.customization, setClassCurrent]);

  const processTypes = {
    unenrollment: 'Desenturmamento',
    disconnect: 'Desligamento',
  };

  return (
    <ClassFormContainer>
      {isLoading && <Spinner />}

      <DirectoryContainer footer={true}>
        <H6 fontWeight={800} status="quaternary">
          DADOS PRINCIPAIS
        </H6>

        <AccordionInfoItem
          title="Dados Principais"
          hasErrors={hasError(['name', 'type', 'age_groups', 'start_date', 'status', 'customization'], errors)}
        >
          <CustomForm style={{ minHeight: '380px' }} status="quaternary" customInputColorText="#542E91">
            <Input
              label="Nome da Turma:"
              name="name"
              defaultValue={classCurrent.name}
              onChange={(event) => handleChange(event)}
              error={errorMessageBuilder('name', errors)}
              useTooltipError={false}
            />
            <Input
              label="Alunos Previstos:"
              name="expected_students"
              datatype="number"
              type="number"
              defaultValue={classCurrent.expected_students}
              onChange={(event) => handleChange(event)}
              error={errorMessageBuilder('expected_students', errors)}
              useTooltipError={false}
            />
            <Checkbox
              key={`${customizationClass}`}
              id={`${classCurrent.customization}Id`}
              name="Turma com Modificações"
              label="Turma com Modificações"
              placeholder="Modificação da turma"
              icon={FiCheck}
              status="basic"
              defaultChecked={customizationClass}
              /*    onChange={(event) => handleChangeCheckboxCustomizationClass(event)} */
              onChange={(event) => handleChangeCheckboxCustomizationClass(event)}
              hideIconUncheck
              semiRound
            />
            {customizationClass && (
              <Textarea
                label="Modificações da Turma:"
                name="customization"
                value={classCurrent.customization ? classCurrent.customization : undefined}
                rows={4}
                onChange={(event) => handleChange(event)}
                error={errorMessageBuilder('customization', errors)}
                useTooltipError={false}
                required
              />
            )}

            <InputDatePicker
              key={`${classCurrent.start_date}`}
              handleChangeDate={(date) =>
                setClassCurrent((oldState: IClass) => {
                  return { ...oldState, start_date: datePickerFormat(date, '-', '/') };
                })
              }
              defaultValue={classCurrent.start_date && classCurrent.start_date.split('-').reverse().join('/')}
              disabled
              input={{
                label: 'Data de início:',
                name: 'start_date',
                placeholder: 'Dia/Mês/Ano',
                useTooltipError: false,
                error: errorMessageBuilder('start_date', errors),
              }}
            />

            <Select
              label="Tipo de Turma:"
              iconName="arrow-down"
              placeholder="Selecione"
              options={defaultType(classCurrent.type)}
              handleSelectChange={(option) => handleSelectChange(option, 'type')}
              error={errorMessageBuilder('type', errors)}
            />

            <Select
              customStyle={{ marginTop: '1.5rem' }}
              label="Modalidade da Turma:"
              iconName="arrow-down"
              placeholder="Selecione"
              options={defaultModality(classCurrent.modality)}
              handleSelectChange={(option) => handleSelectChange(option, 'modality')}
              error={errorMessageBuilder('modality', errors)}
            />

            <AgeGroupClass>
              <label className="title">Grupo Etário</label>
              <div className="all-age-groups-checkbox">
                {AGE_GROUP_CONSTRAINTS.map((age_group) => (
                  <Checkbox
                    key={`${age_group.value} ${classCurrent.age_groups}`}
                    hideIconUncheck
                    icon={FiCheck}
                    status="basic"
                    semiRound
                    id={age_group.value}
                    name={age_group.value}
                    label={age_group.name}
                    defaultChecked={classCurrent.age_groups.includes(age_group.value)}
                    onChange={(event) => handleChangeCheckbox(event)}
                  />
                ))}
              </div>
              <span className="error-checkbox-age-groups">{errorMessageBuilder('age_groups', errors)}</span>
            </AgeGroupClass>
            <Select
              customStyle={{ marginTop: '1rem' }}
              label="Status da Turma:"
              iconName="arrow-down"
              placeholder="Selecione"
              options={defaultClassStatus(classCurrent.status)}
              handleSelectChange={(option) => handleSelectChange(option, 'status')}
              error={errorMessageBuilder('status', errors)}
            />
            <TagsRender
              label="Tags: "
              status="quaternary"
              tags={classCurrent.tags}
              canRemoveTag={true}
              tagCallback={(index: number) => removeItemFromArray(index, 'tags')}
              pathToRedirect="/diretorio/turmas/selecionar/tags"
            />
          </CustomForm>
        </AccordionInfoItem>

        <Divider width="100%" />

        <H6 fontWeight={800} status="quaternary">
          RELACIONADOS
        </H6>
        <ParentRender
          customStyles={{ padding: 0, marginBottom: '2rem' }}
          handleOnClick={() => navigate(pathEnvolvedPlaces)}
          label="Local da Turma"
          hasParent={!!classCurrent.place}
          error={errorMessageBuilder('place', errors)}
        >
          {classCurrent.place && (
            <PlaceCard
              place={classCurrent.place}
              isInputCard={true}
              customStyle={{ margin: '1rem' }}
              canDeleteCard={{ removeCallback: () => clearParent('place') }}
            />
          )}
        </ParentRender>

        <ParentRender
          customStyles={{ padding: 0, marginBottom: '2rem' }}
          handleOnClick={() => navigate(pathEnvolvedGroups)}
          label="Grupo da Turma"
          hasParent={!!classCurrent.group}
          error={errorMessageBuilder('group', errors)}
        >
          {classCurrent.group && (
            <GroupCard
              group={classCurrent.group}
              inputCard={true}
              customStyle={{ margin: '1rem' }}
              canDeleteCard={{ removeCallback: () => clearParent('group') }}
            />
          )}
        </ParentRender>

        {classCurrent.place && (
          <AccordionInfoItem
            title={`Horário (${classCurrent.calendar_data && classCurrent.calendar_data.length > 0 ? classCurrent.calendar_data?.length : 0})`}
          >
            <ButtonAddCard text="Inserir Horário" handleOnClick={() => navigate(pathToInsertTime)} />
            {classCurrent.calendar_data?.map((classTime, index) => (
              <DateTimeRender
                canDeleteCard={{ indexId: index, removeCallback: () => removeItemFromArray(index, 'calendar_data') }}
                key={index}
                timeStart={classTime.start}
                timeEnd={classTime.end}
                timeBreak={classTime.break}
                date={translateDays(classTime.week_days)}
              />
            ))}
          </AccordionInfoItem>
        )}

        <Divider width="100%" />

        <H6 fontWeight={800} status="quaternary">
          PARTICIPANTES/MEMBROS
        </H6>

        <AccordionInfoItem
          title={`Líderes (${classCurrent.teachers ? classCurrent.teachers.length : 0})`}
          hasErrors={hasError(['teacher_ids'], errors)}
        >
          <ButtonAddCard text="Inserir Lideres" handleOnClick={() => navigate(pathEnvolvedUsers)} />
          {classCurrent.teachers?.map((teacher: IUser, index: number) => {
            const isDisabled = classCurrent.update_teachers?.unenroll?.find((un) => Number(un.id) === Number(teacher.id));
            const inEnrollProcess = classCurrent.update_teachers?.enroll?.findIndex((value) => Number(value) === Number(teacher.id)) ?? -1;

            const teacherCardProp =
              inEnrollProcess >= 0
                ? {
                    canDeleteCard: {
                      indexId: index,
                      removeCallback: () => {
                        removeItemFromArray(index, 'teachers');
                        removeFromEnroll(inEnrollProcess, 'update_teachers');
                      },
                    },
                  }
                : {
                    customForm: (
                      <DeleteCustomForm
                        userId={Number(teacher.id)}
                        handleSubmit={(data, category) => setRemoveItem(data, category, 'id')}
                        category="update_teachers"
                      />
                    ),
                  };

            return (
              <div key={index}>
                <UserCard isDisabled={!!isDisabled} key={teacher.id} user={teacher} canSelect={false} {...teacherCardProp} />
                {!!isDisabled && <AlertMessage text={`Em Processo de ${processTypes[isDisabled.type]}`} process="unenrollment" />}
                {inEnrollProcess >= 0 && <AlertMessage text={'Em Processo de Enturmamento'} iconFill="#FFAA00" process="enrollment" />}
              </div>
            );
          })}

          <InputErrorMessage error={errorMessageBuilder('teacher_ids', errors) || ''} />
        </AccordionInfoItem>

        <AccordionInfoItem title={`Alunos (${classCurrent.enrollments ? classCurrent.enrollments.filter((enroll) => enroll.student).length : 0})`}>
          {classCurrent.type !== 'trial' ? (
            pathEnvolvedStudents && <ButtonAddCard text="Inserir Alunos" handleOnClick={() => navigate(pathEnvolvedStudents)} />
          ) : (
            <RedirectSuggestionAlert
              message="Para inserir alunos em uma turma gratuita, utilize a ferramenta de Enturmamento."
              buttonName="Enturmamento"
              redirectTo="/assinaturas/contexto/grupos"
              buttonIcon={<Icon name="person-add" fill="#FFFFFF" />}
              buttonColor="linear-gradient(45deg, #482B85 0%, #6749BA 100%)"
            />
          )}
          {classCurrent.enrollments &&
            classCurrent.enrollments.map((en, index) => {
              const isDisabled = classCurrent.update_students?.unenroll?.find((un) => Number(un.id) === Number(en.student?.id));
              const inEnrollProcess = classCurrent.update_students?.enroll?.findIndex((value) => Number(value) === Number(en.student?.id)) ?? -1;

              const studentCardProp =
                inEnrollProcess >= 0
                  ? {
                      canDeleteCard: {
                        indexId: index,
                        removeCallback: () => {
                          removeItemFromArray(index, 'enrollments');
                          removeFromEnroll(inEnrollProcess, 'update_students');
                        },
                      },
                    }
                  : {
                      customForm: (
                        <DeleteCustomForm
                          userId={Number(en.student?.id)}
                          handleSubmit={(data, category) => setRemoveItem(data, category, 'id')}
                          category="update_students"
                        />
                      ),
                    };

              return (
                en.student && (
                  <div key={index}>
                    <StudentCard isDisabled={!!isDisabled} {...studentCardProp} student={en.student} key={en.student.id} />
                    {!!isDisabled && <AlertMessage text={`Em Processo de ${processTypes[isDisabled.type]}`} />}
                    {inEnrollProcess >= 0 && <AlertMessage text={'Em Processo de Enturmamento'} iconFill="#FFAA00" process="enrollment" />}
                  </div>
                )
              );
            })}
        </AccordionInfoItem>
      </DirectoryContainer>
    </ClassFormContainer>
  );
};

export default ClassForm;
