import React, { Fragment, useCallback, useState } from 'react';
import { useGroup } from '@modules/groups/context/GroupContext';
import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import CustomForm from '@shared/components/CustomForm';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import Divider from '@shared/components/Divider';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import PageFooter from '@shared/components/PageFooter';
import { Input, Button, H6, Spinner, Select } from 'react-alicerce-components';
import { useNavigate } from 'react-router-dom';
import PlaceCard from '@modules/places/components/PlaceCard';
import UserCard from '@modules/users/components/UserCard';
import TagsRender from '@modules/tags/components/TagsRender';
import { beforeSubmitForm, errorMessageBuilder, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import { formatGroup } from '@modules/groups/utils/formatGroup';
import { groupSchema } from '@modules/groups/utils/groupSchema';
import GroupCard from '../GroupCard';
import { removeItemFromArrayByIndex } from '@shared/utils/removeIndexFromArray';
import ButtonAddCard from '@shared/components/ButtonAddCard';
import ParentRender from '@shared/components/ParentRender';
import { defaultGroupCategory } from '../../utils/validData/validGroupCategory';
import { defaultGroupStatus } from '@modules/groups/utils/validData/validGroupStatus';

interface IGroupFormProps {
  saveModalCallback?: (value: boolean) => void;
  deleteModalCallback?: (value: boolean) => void;
  title: string;
  pathParentGroup: string;
  pathEnvolvedPlaces: string;
  pathEnvolvedUsers: string;
  pathToReturn: string | undefined;
}

const GroupForm: React.FC<IGroupFormProps> = ({ saveModalCallback, deleteModalCallback, ...props }) => {
  const [errors, setErros] = useState<IInputErrorsFormat[]>([]);
  const navigate = useNavigate();
  const { groupCurrent, setGroupCurrent, clearGroupContextData, setSelectedGroup } = useGroup();
  const { isLoading } = useGlobal();

  const removeTagFromGroup = useCallback(
    (indexTag: number) => {
      const newTags = removeItemFromArrayByIndex(indexTag, groupCurrent.tags || []);
      setGroupCurrent((oldState: any) => {
        return { ...oldState, tags: newTags };
      });
    },
    [groupCurrent.tags, setGroupCurrent]
  );

  const canSaveGroup = useCallback(async () => {
    const formatedBody = formatGroup(groupCurrent);
    const formErros = await beforeSubmitForm(formatedBody, groupSchema);
    setErros(formErros);
    if (formErros.length === 0) saveModalCallback && saveModalCallback(true);
  }, [saveModalCallback, groupCurrent]);

  const handleChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = event.target.value;
      const inputName = event.target.name;
      setGroupCurrent((oldState: any) => {
        return { ...oldState, [inputName]: inputValue };
      });
    },
    [setGroupCurrent]
  );

  const handleCancelEdit = useCallback(() => {
    clearGroupContextData();

    if (groupCurrent.id) {
      navigate(`/grupo/${groupCurrent.id}/informacoes`);
      return;
    }

    navigate(`/diretorio/grupos`);
  }, [navigate, groupCurrent.id, clearGroupContextData]);

  const removePlaceCard = useCallback(
    (index: number) => {
      const newPlaces = removeItemFromArrayByIndex(index, groupCurrent.places);
      setGroupCurrent((oldState: any) => {
        return { ...oldState, places: newPlaces };
      });
    },
    [groupCurrent.places, setGroupCurrent]
  );

  const removeUserCard = useCallback(
    (index: number) => {
      const newUsers = groupCurrent.lms_users.filter((p, id) => id !== index);
      setGroupCurrent((oldState: any) => {
        return { ...oldState, lms_users: newUsers };
      });
    },
    [groupCurrent.lms_users, setGroupCurrent]
  );

  const clearParentGroup = useCallback(() => {
    setSelectedGroup(undefined);
    setGroupCurrent((oldState: any) => {
      return { ...oldState, parent: undefined };
    });
  }, [setGroupCurrent, setSelectedGroup]);

  const handleSelectChange = useCallback(
    (keyName: string, keyValue?: any) => {
      setGroupCurrent((oldState: any) => {
        return { ...oldState, [keyName]: keyValue };
      });
    },
    [setGroupCurrent]
  );

  const handleOnClickReturn = () => {
    clearGroupContextData();
    props.pathToReturn ? navigate(props.pathToReturn) : navigate(-1);
  };

  return (
    <Fragment>
      {isLoading && <Spinner />}
      <HeaderDirectory
        status="tertiary"
        title={props.title}
        onClickReturn={handleOnClickReturn}
        menuKebab={{
          deleteCallback: deleteModalCallback ? () => deleteModalCallback(true) : undefined,
        }}
      />
      <DirectoryContainer footer={true}>
        <H6 fontWeight={800} status="tertiary">
          DADOS DO GRUPO
        </H6>

        <CustomForm status="tertiary">
          <Input
            onChange={(event) => handleChangeInput(event)}
            name="name"
            label="Nome do Grupo:"
            placeholder="Adicione Nome ao Grupo"
            defaultValue={groupCurrent?.name}
            useTooltipError={false}
            error={errorMessageBuilder('name', errors)}
          />
          <Select
            label="Categoria:"
            iconName="arrow-down"
            placeholder="Selecione"
            handleSelectChange={(option) => handleSelectChange('category', option?.value)}
            options={defaultGroupCategory(groupCurrent.category)}
            error={errorMessageBuilder('category', errors)}
          />

          <Select
            customStyle={{ marginTop: '1.5rem' }}
            label="Status do Local:"
            iconName="arrow-down"
            placeholder="Selecione"
            handleSelectChange={(option) => handleSelectChange('status', option?.value)}
            options={defaultGroupStatus(groupCurrent?.status)}
            error={errorMessageBuilder('status', errors)}
          />

          <ParentRender
            handleOnClick={() => navigate(props.pathParentGroup)}
            label="Grupo Mãe"
            hasParent={!!groupCurrent?.parent}
            customStyles={{ margin: '1.5rem 0' }}
          >
            {groupCurrent.parent && (
              <GroupCard
                canSelect={false}
                group={groupCurrent.parent}
                inputCard={true}
                canDeleteCard={{ removeCallback: () => clearParentGroup() }}
                customStyle={{ margin: '1rem' }}
              />
            )}
          </ParentRender>

          <TagsRender
            tagCallback={removeTagFromGroup}
            canRemoveTag={true}
            label="Tags: "
            status="tertiary"
            tags={groupCurrent.tags}
            pathToRedirect="/diretorio/grupos/selecionar/tags"
          />

          <Divider width="100%" />
          <H6 fontWeight={800} status="tertiary">
            LOCAIS RELACIONADOS
          </H6>

          <AccordionInfoItem status="tertiary" title={`Locais Englobados (${groupCurrent.places ? groupCurrent.places.length : 0})`}>
            <ButtonAddCard text="Inserir Locais Englobados" handleOnClick={() => navigate(props.pathEnvolvedPlaces)} />

            <div className="cards-container">
              {groupCurrent.places &&
                groupCurrent.places.map((p, index) => (
                  <PlaceCard
                    key={p.id}
                    canSelect={undefined}
                    place={p}
                    canDeleteCard={{ indexId: index, removeCallback: () => removePlaceCard(index) }}
                  />
                ))}
            </div>
          </AccordionInfoItem>

          <Divider width="100%" />
          <H6 fontWeight={800} status="tertiary">
            ADMINISTRADORES
          </H6>

          <AccordionInfoItem status="tertiary" title={`Usuários (${groupCurrent.lms_users ? groupCurrent.lms_users.length : 0})`}>
            <ButtonAddCard text="Inserir Usuários" handleOnClick={() => navigate(props.pathEnvolvedUsers)} />

            <div className="cards-container">
              {groupCurrent.lms_users &&
                groupCurrent.lms_users.map((user, index) => (
                  <UserCard
                    key={user.id}
                    canSelect={false}
                    user={user}
                    canDeleteCard={{ indexId: index, removeCallback: () => removeUserCard(index) }}
                  />
                ))}
            </div>
          </AccordionInfoItem>

          <PageFooter>
            <Button transform="none" outline status="tertiary" shaded onClick={handleCancelEdit}>
              Cancelar
            </Button>
            <Button transform="none" status="tertiary" shaded onClick={() => canSaveGroup()}>
              Salvar
            </Button>
          </PageFooter>
        </CustomForm>
      </DirectoryContainer>
    </Fragment>
  );
};

export default GroupForm;
