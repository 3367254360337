
import { useParams } from 'react-router-dom';
import { Container } from './styles';
import { MetabaseDashs } from '@shared/components/MetabaseDashs';
import React from 'react';

const IndividualLearningHistory: React.FC = () => {
  const { studentId } = useParams<{ studentId: string }>();

  return (
    <Container>
        <MetabaseDashs
          iframe={
            <iframe
            title="Individual Learning History"
            // @TODO felipefga - Esse grafico sempre aponta pra produção, homolog e dev preciaremos criar os iframes para cada ambiente
            src={`https://data.alicerceedu.com.br/public/dashboard/8a0ad171-1ca5-4de5-aaa3-cfdc0e78828a?id=${studentId}`}
            width="100%"
            height="700"
            allowTransparency
          />
        }
        slots={{
          filterHider: { style: { width: '100%', height: '70px', position: 'absolute', top: '75px', left: 0, background: 'transparent' }},
          footerHider: { style: { 
            borderTop: '3px solid #9A9A9A',
            width: '100%', height: '70px', position: 'absolute', bottom: '0', left: 0, background: 'white' }}
        }}
      />
    </Container>
  );
};

export default IndividualLearningHistory;
