import React from "react"

type MetabaseDashsProps = {
  iframe: React.ReactNode
  slots?: {
    filterHider?: React.HTMLAttributes<HTMLDivElement>
    footerHider?: React.HTMLAttributes<HTMLDivElement>
  }
}
export const MetabaseDashs = ({ iframe, slots }: MetabaseDashsProps) => {

  return (
    <div style={{ position: 'relative' }}>
      <div className="filter-hider" style={slots?.filterHider?.style}/>
      {iframe}
      <div className="footer-hider" style={slots?.footerHider?.style}/>
    </div>
  )
}