import React, { useCallback } from 'react';

import { Button, Checkbox, H6, Input, Select, Spinner, Textarea } from 'react-alicerce-components';

import CustomForm from '@shared/components/CustomForm';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import DirectoryContainer from '@shared/components/DirectoryContainer';
import AccordionInfoItem from '@shared/components/AccordionInfoItem';
import Divider from '@shared/components/Divider';
import PageFooter from '@shared/components/PageFooter';

import TagsRender from '@modules/tags/components/TagsRender';

import { PlaceFormContainer } from './styles';
import { useNavigate } from 'react-router-dom';
import { errorMessageBuilder, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import UserCard from '@modules/users/components/UserCard';
import PlaceCard from '@modules/places/components/PlaceCard';
import IPlace from '@modules/places/interfaces/IPlace';
import { usePlaces } from '@modules/places/context/PlacesContext';
import { removeItemFromArrayByIndex } from '@shared/utils/removeIndexFromArray';
import { FiCheck } from 'react-icons/fi';

import ParentRender from '@shared/components/ParentRender';
import ButtonAddCard from '@shared/components/ButtonAddCard';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import InputErrorMessage from '@shared/components/ErrorMessages/InputErrorMessage';
import { defaultPlaceCategory } from '../../utils/validData/validPlaceCategory';
import { defaultPlaceStatus } from '@modules/places/utils/validData/validPlaceStatus';

interface IPlaceForm {
  headerTitle: string;
  headerPathToReturn: string;
  pathParentPlace: string;
  pathAddTags: string;
  pathAddAdmins: string;
  errors: IInputErrorsFormat[];
  handleCancel: () => void;
  deleteCallback?: () => void;
  handleConfirmButton: () => Promise<void>;
}

const PlaceForm: React.FC<IPlaceForm> = ({
  headerTitle,
  headerPathToReturn,
  pathParentPlace,
  pathAddTags,
  pathAddAdmins,
  errors,
  handleCancel,
  deleteCallback,
  handleConfirmButton,
}) => {
  const navigate = useNavigate();
  const { setPlaceCurrent, placeCurrent, clearPlaceCreate } = usePlaces();
  const { isLoading } = useGlobal();

  const handleChangeInput = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>, subLevel?: string) => {
      const inputValue = event.target.value;
      const inputName = event.target.name;
      setPlaceCurrent((oldState: IPlace) => {
        if (subLevel) return { ...oldState, [subLevel]: { ...oldState[subLevel], [inputName]: inputValue } };
        return { ...oldState, [inputName]: inputValue };
      });
    },
    [setPlaceCurrent]
  );

  const handleChangeCheckbox = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const inputName = event.target.name;
      const checked = event.target.checked;
      setPlaceCurrent((oldState: any) => {
        return { ...oldState, [inputName]: checked };
      });
    },
    [setPlaceCurrent]
  );

  const handleSelectChange = useCallback(
    (keyName: string, keyValue?: any) => {
      setPlaceCurrent((oldState: any) => {
        return { ...oldState, [keyName]: keyValue };
      });
    },
    [setPlaceCurrent]
  );

  const removeUserCard = useCallback(
    (index: number) => {
      if (!placeCurrent?.admins) return;
      const newUser = removeItemFromArrayByIndex(index, placeCurrent?.admins);
      setPlaceCurrent((oldState: any) => {
        return { ...oldState, admins: newUser };
      });
    },
    [placeCurrent, setPlaceCurrent]
  );

  const removeParentPlace = useCallback(() => {
    setPlaceCurrent((oldState: any) => {
      return { ...oldState, parent: undefined };
    });
  }, [setPlaceCurrent]);

  const removeTagFromPlace = useCallback(
    (indexTag: number) => {
      const newTags = removeItemFromArrayByIndex(indexTag, placeCurrent?.tags || []);
      setPlaceCurrent((oldState: any) => {
        return { ...oldState, tags: newTags };
      });
    },
    [placeCurrent, setPlaceCurrent]
  );

  return (
    <PlaceFormContainer>
      {isLoading && <Spinner />}
      <HeaderDirectory
        title={headerTitle}
        status="primary"
        pathToReturn={headerPathToReturn}
        onClickReturn={clearPlaceCreate}
        menuKebab={{
          deleteCallback: deleteCallback,
        }}
      />

      <DirectoryContainer footer={true}>
        <H6 fontWeight={800} status="primary">
          DADOS DO LOCAL
        </H6>

        <CustomForm status="primary">
          <Input
            label="Nome do Local:"
            name="name"
            placeholder="Adicione Nome ao Local"
            onChange={(event) => handleChangeInput(event)}
            defaultValue={placeCurrent?.name}
            useTooltipError={false}
            error={errorMessageBuilder('name', errors)}
          />
          <Select
            label="Categoria:"
            iconName="arrow-down"
            placeholder="Selecione"
            handleSelectChange={(option) => handleSelectChange('category', option?.value)}
            options={defaultPlaceCategory(placeCurrent?.category)}
            error={errorMessageBuilder('category', errors)}
          />
          <Select
            customStyle={{ marginTop: '1.5rem' }}
            label="Status do Local:"
            iconName="arrow-down"
            placeholder="Selecione"
            handleSelectChange={(option) => handleSelectChange('status', option?.value)}
            options={defaultPlaceStatus(placeCurrent?.status)}
            error={errorMessageBuilder('status', errors)}
          />

          <ParentRender
            customStyles={{ margin: '1.5rem 0rem' }}
            handleOnClick={() => navigate(pathParentPlace)}
            label="Local Mãe"
            hasParent={!!placeCurrent?.parent}
          >
            {placeCurrent?.parent && (
              <PlaceCard
                place={placeCurrent?.parent}
                isInputCard={true}
                canDeleteCard={{ removeCallback: () => removeParentPlace() }}
                customStyle={{ margin: '1rem' }}
              />
            )}
          </ParentRender>

          <Input
            label="Endereço:"
            name="address"
            placeholder="Logradouro, Rua e Bairro"
            onChange={(event) => handleChangeInput(event, 'address_data')}
            defaultValue={placeCurrent?.address_data?.address}
            useTooltipError={false}
            error={errorMessageBuilder('address', errors)}
          />
          <div className="wrapper-input">
            <Input
              name="city"
              placeholder="Cidade"
              onChange={(event) => handleChangeInput(event, 'address_data')}
              defaultValue={placeCurrent?.address_data?.city}
              useTooltipError={false}
              error={errorMessageBuilder('city', errors)}
            />
            <Input
              name="uf"
              placeholder="UF"
              onChange={(event) => handleChangeInput(event, 'address_data')}
              defaultValue={placeCurrent?.address_data?.uf}
              maxLength={2}
              useTooltipError={false}
              error={errorMessageBuilder('uf', errors)}
            />
          </div>
          <InputErrorMessage error={errorMessageBuilder('address_data', errors)} />

          <div className="container-online-place  ">
            <Checkbox
              hideIconUncheck
              icon={FiCheck}
              status="basic"
              semiRound
              id={'1'}
              name="virtual_place"
              label="Sala Virtual"
              onChange={(event) => handleChangeCheckbox(event)}
              checked={placeCurrent?.virtual_place ? placeCurrent.virtual_place : false}
            />
            <Input
              name="place_url"
              placeholder="Adicione Endereço URL"
              onChange={(event) => handleChangeInput(event)}
              defaultValue={placeCurrent?.place_url}
              disabled={!placeCurrent?.virtual_place}
              useTooltipError={false}
              error={errorMessageBuilder('place_url', errors)}
            />
          </div>

          <TagsRender
            label="Tags: "
            status="primary"
            tags={placeCurrent?.tags}
            pathToRedirect={pathAddTags}
            canRemoveTag={true}
            tagCallback={removeTagFromPlace}
          />

          <Textarea
            name="description"
            label="Descrição do Local:"
            placeholder="Adicione Descrição ao Lugar"
            rows={4}
            onChange={(event) => handleChangeInput(event)}
            value={placeCurrent?.description}
          />
          <Divider width="100%" />
          <H6 fontWeight={800} status="primary">
            ADMINISTRADORES
          </H6>

          <AccordionInfoItem title={`Usuários (${placeCurrent?.admins ? placeCurrent?.admins.length : 0})`} status="primary">
            <ButtonAddCard text="Inserir Coordenadores" handleOnClick={() => navigate(pathAddAdmins)} />
            {placeCurrent?.admins?.map((user, index) => (
              <UserCard key={user.id} canSelect={false} user={user} canDeleteCard={{ indexId: index, removeCallback: () => removeUserCard(index) }} />
            ))}
          </AccordionInfoItem>
        </CustomForm>
        <PageFooter>
          <Button transform="none" status="primary" statusNumber={400} shaded outline onClick={handleCancel}>
            Cancelar
          </Button>
          <Button transform="none" status="primary" statusNumber={500} shaded onClick={handleConfirmButton}>
            Salvar
          </Button>
        </PageFooter>
      </DirectoryContainer>
    </PlaceFormContainer>
  );
};

export default PlaceForm;
