import ApiFactory from "@shared/factories/ApiFactory";
import axios, { AxiosInstance, CancelTokenSource } from "axios";
import IPlace from "@modules/places/interfaces/IPlace";

class UpdatePlaceService {
  private api: AxiosInstance;

  constructor(authToken?: string | null, cancelTokenSource?: CancelTokenSource) {
    if (!authToken) return;

    this.api = ApiFactory.create({
      token: authToken,
      cancelTokenSource
    });
  }

  public async execute(placeId: string | number, placeBody: IPlace): Promise<{ success: boolean, message: string }> {
    try {
      await this.api.put(`place/${placeId}`, placeBody);
      return { success: true, message: 'Local atualizado com sucesso' };
    } catch (error: any) {
      if (axios.isCancel(error)) return { success: false, message: 'Requisição cancelada' };
      else console.error(`Error: ${error}`);
      return { success: false, message: error.response.data.message };
    }
  }
}

export default UpdatePlaceService;
