import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Button, useToast } from 'react-alicerce-components';
import Icon from 'react-eva-icons';
import { useNavigate, useParams } from 'react-router-dom';

import ClassForm from '@modules/classes/components/ClassForm';
import { useClass } from '@modules/classes/context/ClassContext';
import DeleteClassService from '@modules/classes/services/DeleteClassService';
import EditClassService from '@modules/classes/services/EditClassService';
import GetClassService from '@modules/classes/services/GetClassService';
import { formatUpdateClass } from '@modules/classes/utils/formatClass';
import ConfirmModal from '@shared/components/ConfirmModal';
import { useGlobal } from '@shared/contexts/global/GlobalContext';
import useCancelRequest from '@shared/hooks/useCancelRequest';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import useAuth from '@shared/store/auth/hook';
import HeaderDirectory from '@shared/components/Headers/HeaderDirectory';
import PageFooter from '@shared/components/PageFooter';
import { IInputErrorsFormat, beforeSubmitForm } from '@shared/utils/beforeSubmitForm';
import { classSchema } from '../../utils/classSchema';

const ClassCreate: React.FC = () => {
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [errors, setErros] = useState<IInputErrorsFormat[]>([]);
  const { setIsLoading } = useGlobal();
  const navigate = useNavigate();
  const { classCurrent, setClassCurrent, clearClassContextData } = useClass();
  const { signedUser } = useAuth();
  const { addToast } = useToast();
  const { newCancelToken } = useCancelRequest();
  const isMounted = useIsMounted();
  const classIdInPath = useParams<{ id: string }>();

  const handleEditClass = useCallback(async () => {
    if (!signedUser || !classCurrent || !classCurrent.id) return;
    setShowEditModal(false);
    const formatedBody = formatUpdateClass(classCurrent);
    const { isCustomClass, ...body } = formatedBody;
    setIsLoading(true);

    const res = await new EditClassService(signedUser.token, newCancelToken()).execute(classCurrent.id, body);
    if (!isMounted) return;
    setIsLoading(false);

    if (!res) {
      addToast({
        status: 'danger',
        title: 'Turmas',
        description: 'Não foi possível editar o Turma.',
      });
      return;
    }

    addToast({
      status: 'success',
      title: 'Turmas',
      description: 'Turma editado com sucesso.',
    });

    return navigate(`/turma/${classCurrent.id}/informacoes`);
  }, [signedUser, classCurrent, setIsLoading, newCancelToken, isMounted, addToast, navigate]);

  const handleDeleteClass = useCallback(async () => {
    setShowDeleteModal(false);
    if (!classCurrent.id) return;
    setIsLoading(true);
    const res = await new DeleteClassService(signedUser?.token).execute(classCurrent.id);
    setIsLoading(false);
    if (res) {
      addToast({
        status: 'success',
        title: 'Turmas',
        description: 'Turma deletado com sucesso.',
      });
      return navigate(`/diretorio/turmas`);
    }

    addToast({
      status: 'danger',
      title: 'Turmas',
      description: 'Não foi possível deletar o Turma.',
    });
  }, [classCurrent.id, setIsLoading, signedUser, addToast, navigate]);

  const canSaveClass = useCallback(async () => {
    const formatedBody = formatUpdateClass(classCurrent);
    let formErros = await beforeSubmitForm(formatedBody, classSchema);
    if (!classCurrent.place) formErros = [...formErros, { name: 'place', message: 'É necessário selecionar um Lugar' }];
    if (!classCurrent.group) formErros = [...formErros, { name: 'group', message: 'É necessário selecionar um Grupo' }];
    setErros(formErros);
    if (formErros.length === 0) setShowEditModal(true);
  }, [classCurrent]);

  const handleCancelEdit = useCallback(() => {
    clearClassContextData();
    if (classCurrent.id) {
      navigate(`/turma/${classCurrent.id}/informacoes`);
      return;
    }
    navigate(`/diretorio/turmas`);
  }, [navigate, classCurrent.id, clearClassContextData]);

  const getClass = useCallback(async () => {
    if (!classIdInPath.id) return navigate('/minhas-turmas');
    setIsLoading(true);
    const classFound = await new GetClassService(signedUser?.token).execute(classIdInPath.id);
    setIsLoading(false);
    if (!classFound) return navigate('/minhas-turmas');
    
    setClassCurrent(classFound);
  }, [classIdInPath, navigate, setClassCurrent, setIsLoading, signedUser]);
  
  useEffect(() => {
    if(!classCurrent.id) getClass();
  }, [getClass, classCurrent.id]);

  return (
    <Fragment>
      <HeaderDirectory
        status="quaternary"
        title="Editar Turma"
        pathToReturn="/diretorio/turmas"
        hideGoBack={true}
        onClickReturn={() => clearClassContextData()}
        menuKebab={{
          deleteCallback: () => setShowDeleteModal(true),
        }}
      />

      <ClassForm
        pathEnvolvedGroups="/diretorio/turmas/selecionar/grupos"
        pathEnvolvedPlaces="/diretorio/turmas/selecionar/locais"
        pathEnvolvedUsers="/diretorio/turmas/selecionar/lideres"
        pathEnvolvedStudents="/diretorio/turmas/selecionar/alunos"
        pathToInsertTime={`/diretorio/turmas/selecionar/dias-e-horarios`}
        formAction={'editing'}
        errors={errors}
      />

      <PageFooter>
        <Button transform="none" outline status="quaternary" statusNumber={400} shaded onClick={handleCancelEdit}>
          Cancelar
        </Button>
        <Button transform="none" status="quaternary" shaded onClick={() => canSaveClass()}>
          Salvar
        </Button>
      </PageFooter>

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showEditModal}
        closeCallback={() => setShowEditModal(!showEditModal)}
        title="Deseja Salvar Alterações?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={() => handleEditClass()}
      />

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showDeleteModal}
        closeCallback={() => setShowDeleteModal(!showDeleteModal)}
        title="Deseja Deletar a Turma?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={() => handleDeleteClass()}
      />
    </Fragment>
  );
};

export default ClassCreate;
