import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Icon from 'react-eva-icons';

import { useToast } from 'react-alicerce-components';

import { usePlaces } from '@modules/places/context/PlacesContext';

import { useNavigate, useParams } from 'react-router-dom';
import useAuth from '@shared/store/auth/hook';
import { beforeSubmitForm, IInputErrorsFormat } from '@shared/utils/beforeSubmitForm';
import { placeSchema } from '@modules/places/utils/placeSchema';
import UpdatePlaceService from '@modules/places/services/UpdatePlaceService';
import { formatPlace } from '@modules/places/utils/formatPlace';

import { useGlobal } from '@shared/contexts/global/GlobalContext';
import DeletePlaceService from '@modules/places/services/DeletePlaceService';
import ConfirmModal from '@shared/components/ConfirmModal';
import { useIsMounted } from '@shared/hooks/useIsMounted';
import PlaceForm from '@modules/places/components/PlaceForm';

const PlaceEdit: React.FC = () => {
  const navigate = useNavigate();
  const { setIsLoading } = useGlobal();
  const { signedUser } = useAuth();
  const { placeCurrent, clearPlaceCreate, getPlace } = usePlaces();
  const [errors, setErros] = useState<IInputErrorsFormat[]>([]);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const { id } = useParams();
  const { addToast } = useToast();
  const isMounted = useIsMounted();

  const getPlaceFromContext = useCallback(async () => {
    if (!id) return;
    const res = await getPlace(id);
    if (!res) navigate('/diretorio/locais');
  }, [getPlace, navigate, id]);

  useEffect(() => {
    if (!placeCurrent || id !== placeCurrent.id) {
      getPlaceFromContext();
    }
  }, [getPlaceFromContext, id, placeCurrent]);

  const handleCancel = useCallback(() => {
    clearPlaceCreate();
    navigate(`/local/${placeCurrent?.id}/informacoes`);
  }, [clearPlaceCreate, navigate, placeCurrent]);

  const handleEditButton = useCallback(async () => {
    if (!placeCurrent) return;
    const formatedBody = formatPlace(placeCurrent);
    console.log(formatedBody, 'formatedBody');
    const canSubmitForm = await beforeSubmitForm(formatedBody, placeSchema);
    setErros(canSubmitForm);
    if (!!!canSubmitForm.length) setShowEditModal(true);
  }, [placeCurrent]);

  const handleUpdatePlace = useCallback(async () => {
    setShowEditModal(false);
    if (!signedUser || !placeCurrent?.id) return;
    setIsLoading(true);
    const formatedBody = formatPlace(placeCurrent);

    const res = await new UpdatePlaceService(signedUser.token).execute(placeCurrent.id, formatedBody);
    
    if (isMounted() && res.success) {
      setIsLoading(false);
      addToast({
        status: 'success',
        title: 'Locais',
        description: 'Local editado com sucesso.',
      });
      return navigate(`/local/${placeCurrent.id}/informacoes`);
    }
    
    setIsLoading(false);
    addToast({ status: 'danger', title: 'Locais', description: res.message });
  }, [addToast, navigate, isMounted, placeCurrent, setIsLoading, signedUser]);

  const handleDeletePlace = useCallback(async () => {
    setShowDeleteModal(false);
    if (!placeCurrent || !placeCurrent.id) return;
    setIsLoading(true);
    const res = await new DeletePlaceService(signedUser?.token).execute(placeCurrent.id);
    setIsLoading(false);
    if (res) {
      addToast({
        status: 'success',
        title: 'Locais',
        description: 'Local deletado com sucesso.',
      });
      return navigate(`/diretorio/locais`);
    }

    addToast({
      status: 'danger',
      title: 'Locais',
      description: 'Não foi possível deletar o Local.',
    });
  }, [addToast, navigate, placeCurrent, setIsLoading, signedUser]);

  return (
    <Fragment>
      <PlaceForm
        headerTitle="Editar Local"
        headerPathToReturn={`/local/${placeCurrent?.id}/informacoes`}
        errors={errors}
        handleCancel={handleCancel}
        handleConfirmButton={handleEditButton}
        pathParentPlace="/diretorio/locais/editar/selecionar"
        pathAddTags="/diretorio/locais/editar/selecionar/tags"
        pathAddAdmins="/diretorio/locais/editar/selecionar/usuarios"
        deleteCallback={() => setShowDeleteModal(true)}
      />

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showEditModal}
        closeCallback={() => setShowEditModal(!showEditModal)}
        title="Deseja Salvar Alterações ?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={handleUpdatePlace}
      />

      <ConfirmModal
        start={<Icon name="alert-circle-outline" fill="#F2C94C" />}
        showModal={showDeleteModal}
        closeCallback={() => setShowDeleteModal(!showDeleteModal)}
        title="Deseja Deletar o Local?"
        textConfirmButton="Confirmar"
        textCloseButton="Cancelar"
        confirmCallBack={handleDeletePlace}
      />
    </Fragment>
  );
};

export default PlaceEdit;
